
import React from 'react';

const BlogHeader = () => {
    return (
        <section className="section container pt-20px">
            <h1>Blog</h1>
            <hr />
        </section>
    )
}

export default BlogHeader;