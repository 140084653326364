/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"
import FeaturedHeader from "./featured-header"

const InnerLayout = ({ children }) => {
  const data = useStaticQuery(graphql`
  {
    site: site {
      siteMetadata {
        title
      }
    }
    bootstrap: allFile(
      filter: {
        sourceInstanceName: {eq: "bootstrap"}, 
        extension: {eq: "js"}, 
        relativePath: {eq: "js/bootstrap.min.js"},
      }, 
      limit: 1) {
      nodes {
        dir
        relativeDirectory
        relativePath
        publicURL
      }
    }
  }  
  `);


  return (
    <>
      <div className="page-wrapper theme-one">
        <Header siteTitle={data.site.siteMetadata.title}>
        </Header>
        <main>{children}</main>
        <Footer />
      </div>
    </>
  )
}

InnerLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default InnerLayout
