import React from "react"
import { Link } from 'gatsby'
import InnerLayout from "../components/inner-layout"
import SEO from "../components/seo"
import Dump from "../components/dump"
import BlogHeader from "../components/blog-header"
import { graphql } from 'gatsby'

const BlogPage = ({ data }) => (
  <InnerLayout>
    <SEO title="Blog" />
    <BlogHeader />
    <div className="container">
      {data.allMdx.nodes.map(({ excerpt, frontmatter, fields }) => (
        <>
          <Link to={fields.slug}>
            <h4>{frontmatter.title}</h4>
          </Link>
          <p>{excerpt}</p>
          <p>Posted on {new Date(frontmatter.date).toLocaleDateString()}</p>
        </>
      ))}
    </div>
  </InnerLayout>
)

export default BlogPage
export const query = graphql`
  query SITE_INDEX_QUERY {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { published: { eq: true } } }
    ) {
      nodes {
        id
        excerpt(pruneLength: 250)
        frontmatter {
          title
          date
        }
        fields {
          slug
        }
      }
    }
  }
`;
